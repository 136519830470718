import { graphql, Link } from "gatsby"
import React from "react"
import GlobalNavi from "../../components/GlobalNavi"
import { Box } from "@material-ui/core"
import ColorMenuButton from "../../components/ColorMenuButton"
import css from "./WorksIndex.module.scss"
import SEO from "../../components/seo"
import Img from "gatsby-image"

export default function WorksIndex(props) {
  return (
    <div>
      <SEO title={`作品を見る`} />
      <GlobalNavi />

      <div className="l-wrapper">
        <nav aria-label="パンくずリスト">
          <ul className={`breadcrumb`}>
            <li>
              <Link to={`/`}>Home</Link>
            </li>
            <li>
              <Link to={`/works`}>作品を見る</Link>
            </li>
          </ul>
        </nav>

        <h2 className={`fontLvH2`}>展示作品</h2>

        <p className={`fontLvBody`}>
          ここでは、ブックで紹介したすべての作品を簡単にご紹介します。映像作品と音声作品はこのページから鑑賞することができます
        </p>

        <Box
          mt={{ xs: 2.5, sm: 5 }}
          mb={{ xs: 2.5, sm: 5 }}
          className={css.colmun}
          aria-label="展示作品をご紹介します"
        >
          <ColorMenuButton color={`blue`}>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>作品:</span>
              <Link to={"/works/takayuki-yamamoto"}>山本高之</Link>
            </p>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>応答するひと:</span>
              <Link to={`/works/mieko-matsumoto`}>松本美枝子</Link> /{" "}
              <Link to={`/works/mieko-nakagawa`}>中川美枝子</Link>
            </p>
          </ColorMenuButton>

          <ColorMenuButton color={`red`}>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>作品:</span>{" "}
              <Link to={`/works/yuki-kawado`}>川戸由紀</Link>
            </p>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>応答するひと:</span>{" "}
              <Link to={`/works/yuichi-yokoyama`}>横山裕一</Link>
            </p>
          </ColorMenuButton>

          <ColorMenuButton color={`yellow`}>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>作品:</span>
              <Link to={`/works/naoto-iguchi`}>井口直人</Link>
            </p>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>応答するひと:</span>
              <Link to={`/works/masayo-funakoshi`}>船越雅代</Link> /{" "}
              <Link to={`/works/teppei-kaneuji`}>金氏徹平</Link>
            </p>
          </ColorMenuButton>

          <ColorMenuButton color={`gray`}>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>作品:</span>{" "}
              <Link to={`/works/atsushi-sugiura/`}>杉浦篤</Link>
            </p>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>応答するひと:</span>
              <Link to={"/works/dj-sniff"}>dj sniff</Link>
            </p>
          </ColorMenuButton>

          <ColorMenuButton color={`orange`}>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>作品:</span>
              <Link to={`/works/kazumi-kamae`}>鎌江一美</Link>
            </p>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>応答するひと:</span>
              <Link to={`/works/mari-kashiwagi`}>柏木麻里</Link>
            </p>
          </ColorMenuButton>

          <ColorMenuButton color={`black`}>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>作品:</span> リ・ビンユアン{" "}
              <br className={`pc-none`} />《
              <Link to={"/works/binyuan-li_drawingboard"}>画板 100×40</Link>》{" "}
              《
              <Link to={"/works/binyuan-li_until-the-bridge-collapses"}>
                橋が壊れるまで
              </Link>
              》
            </p>
            <p className={`fontLvBodyL`}>
              <span className={`fontLvBody`}>応答するひと:</span>
              <br className={`pc-none`} />
              <Link to={"/works/isokatsu_satoshi-iitsuka"}>
                磯子区障害者地域活動ホーム+飯塚聡
              </Link>{" "}
              / <Link to={"/works/kasetsu"}>華雪</Link>
            </p>
            <div
              className="fontLvBodyS"
              style={{ lineHeight: "1.5em", marginTop: "0.5em" }}
            >
              ＊ 2021年3月31日をもってリ・ビンユアン作品の公開は終了しました。
            </div>
          </ColorMenuButton>

          <div className={css.colmun__fullWidth}>
            <ColorMenuButton color={`green`}>
              <div className={css.jestomBlock}>
                <p className={`fontLvBodyL`}>
                  <span className={`fontLvBody`}>特別上映:</span>
                  <br />
                  <Link to={"/works/jess-thom/"}>
                    ジェス・トム 『Me, My Mouth and I』
                  </Link>
                  <div
                    className="fontLvBodyS"
                    style={{ lineHeight: "1.5em", marginTop: "0.5em" }}
                  >
                    ＊
                    11月24日をもって映画の公開は終了しましたが、ジェス・トムを迎えて行われた11月21日のトークイベントの映像をご覧頂けます。
                  </div>
                </p>
                <figure>
                  <Link to={"/works/jess-thom/"}>
                    <Img fluid={props.data.file.childImageSharp.fluid} />
                  </Link>
                </figure>
              </div>
            </ColorMenuButton>
          </div>
        </Box>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "works/jes-tom.jpg" }) {
      id
      relativePath
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
